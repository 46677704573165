:root {
  --color-blue: #33a7ff;
  --color-green: #4cbe9c;
  --color-amber: #f6c566;
  --color-red: #ff3b58;
  --color-text-light: #f1f1f1;
  --color-text-default: #888888;
  --color-text-disabled: #444444;
  --color-text-value: #cccccc;
  --color-divider: #323232;
  --color-field: #1d1d1d;
}

body {
  background: #303030;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, 'Roboto Mono', Monaco, Consolas, 'Courier New',
    monospace;
}
input,
select {
  background: none;
  border: 0;
  border-bottom: 2px solid #ccc;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  font-size: 18px;
  outline: 0;
}
option {
  padding: 0;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Roboto', sans-serif;
  color: #888888;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Roboto', sans-serif;
  color: #888888;
  font-size: 16px;
}

::placeholder {
  font-family: 'Roboto', sans-serif;
  color: #888888;
  font-size: 16px;
}

button {
  border: none;
  padding: 0px;
  outline: none;
  font-family: 'Roboto', sans-serif;
}

div[id^='__lpform_'] {
  display: none;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.typeform {
  width: 100%;
  max-width: 1280px;
  margin: 90px 0 40px 0;
  height: calc(100vh - 110px);
  border: 2px solid #111111;
  background-color: #1f1f1f;
  border-radius: 10px;
  overflow: hidden;
}

.checkerboard {
  background-color: #1d1d1d;
  background-image: repeating-linear-gradient(
      45deg,
      #282828 25%,
      transparent 25%,
      transparent 75%,
      #282828 75%,
      #282828
    ),
    repeating-linear-gradient(
      45deg,
      #282828 25%,
      #1d1d1d 25%,
      #1d1d1d 75%,
      #282828 75%,
      #282828
    );
  background-position: 0 0, 14px 14px;
  background-size: 28px 28px;
}

input[type='radio'] {
  margin: 0;
  padding: 0;
  appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  display: grid;
  cursor: pointer;
  border-radius: 50%;
  place-content: center;
  box-sizing: border-box;
  background-color: #111111;
}

input[type='radio']::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-text-light);
}

input[type='radio']:checked::before {
  transform: scale(1);
}

@media screen and (min-width: 800px) {
  .typeform {
    margin: 140px 0 20px 0;
    height: calc(100vh - 160px);
    border-radius: 15px;
  }
}
